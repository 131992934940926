var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "multi-line flex col a-start gap4",
      staticStyle: { padding: "0 !important" },
    },
    [
      _vm._l(_vm.data, function (line, i) {
        return [
          line.txt
            ? _c("li", { key: i, staticClass: "flex a-center gap10" }, [
                line.imgUrl
                  ? _c("img", { attrs: { src: line.imgUrl } })
                  : _vm._e(),
                _c("span", { domProps: { textContent: _vm._s(line.txt) } }),
              ])
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }